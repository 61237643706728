import React from 'react';
import Layout from 'components/layout';
import Seo from 'components/seo';
import Cv from 'components/cv';

function CvAppPage() {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <Seo title="Pintarnya Kerja | CV Pintar" />
      <Cv appView={true} />
    </Layout>
  );
}

export default CvAppPage;